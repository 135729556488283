import React, { useState } from "react";
import { generateDocumentDownloadUrl } from "utils/helpers";
import { Link, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Button } from "@skyportal/ui-kit";
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import { Document, Page, pdfjs } from "react-pdf";
import useFetchDocument from "hooks/useFetchDocument";
import PageLayout from "layouts/PageLayout";
import DocumentPreviewLoader from "./DocumentPreviewLoader";
import DocumentPreviewFallback from "./DocumentPreviewFallback";
import { appSelectors } from "store/app";
import config from "config";

import styles from "./styles.module.css";
import stylesOverride from "./stylesOverride.css";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import ModuleBreadcrumbs from "components/ModuleBreadcrumbs";

pdfjs.GlobalWorkerOptions.workerSrc = `https://unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;

export const previewDocumentFormats = ["gif", "jpg", "jpeg", "pdf", "tif", "tiff", "txt"];

const DocumentPreview = () => {
  const { t } = useTranslation("documentPreviewPage");
  const location = useLocation();
  const userProfile = useSelector(appSelectors.getUserProfile);
  const documentIdFromUrl = Number(location.pathname.replace("/documents/preview/", "")) || 0;
  const documentData = useFetchDocument(documentIdFromUrl);
  const [numPages, setNumPages] = useState<number>(1);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [zoomValue, setZoomValue] = useState<number>(1);

  const isPdfDocument = documentData.documentName.split(".").pop() === "pdf";

  function onDocumentLoadSuccess({ numPages }: any) {
    setNumPages(numPages);
  }

  const PdfPreviewHeader = () => {
    return (
      <>
        <ModuleBreadcrumbs />
        <div className={styles.documentPreviewHeader}>
          <Link to="/documents">
            <Button leftIcon="arrow-left" data-testid="backToDocumentsListBtn"></Button>
          </Link>
          <h3 className={styles.documentPreviewHeaderTitle}>{documentData?.documentName}</h3>
          <Button
            type="secondary"
            leftIcon="arrow-left"
            onClick={() => setPageNumber(pageNumber - 1)}
            disabled={pageNumber === 1}
            className={styles.pdfPrevPageButton}
            data-testid="prevPageBtn"
          />
          <span className={styles.pdfCurrentPage}>{`${pageNumber} / ${numPages}`}</span>
          <Button
            type="secondary"
            leftIcon="arrow-right"
            onClick={() => setPageNumber(pageNumber + 1)}
            disabled={pageNumber === numPages}
            className={styles.pdfNextPageButton}
            data-testid="nextPageBtn"
          />
          <Button
            type="secondary"
            leftIcon="zoom-in"
            onClick={() => setZoomValue(zoomValue + 0.1)}
            className={styles.pdfZoomBtn}
          />
          <Button
            type="secondary"
            leftIcon="zoom-out"
            onClick={() => setZoomValue(zoomValue - 0.1)}
            className={styles.pdfZoomBtn}
          />
          <a href={generateDocumentDownloadUrl(documentIdFromUrl)} download={documentData?.documentName}>
            <Button leftIcon="download">{t("common:Download")}</Button>
          </a>
        </div>
      </>
    );
  };

  const DocumentPreviewHeader = () => {
    return (
      <>
        <ModuleBreadcrumbs />
        <div className={styles.documentPreviewHeader}>
          <Link to="/documents">
            <Button leftIcon="arrow-left"></Button>
          </Link>
          <h3 className={styles.documentPreviewHeaderTitle}>{documentData?.documentName}</h3>
          <a href={generateDocumentDownloadUrl(documentIdFromUrl)} download={documentData?.documentName}>
            <Button leftIcon="download">{t("common:Download")}</Button>
          </a>
        </div>
      </>
    );
  };

  return (
    <PageLayout>
      {isPdfDocument ? (
        <>
          <PdfPreviewHeader />
          <Document
            file={generateDocumentDownloadUrl(documentIdFromUrl)}
            loading={DocumentPreviewLoader}
            onLoadSuccess={onDocumentLoadSuccess}
            options={{ httpHeaders: { userid: String(userProfile.id), secret: config.previewDocumentSecret } }}
          >
            <Page
              pageNumber={pageNumber}
              scale={zoomValue}
              loading={DocumentPreviewLoader}
              className={styles.pdfPage}
            />
          </Document>
        </>
      ) : (
        <DocViewer
          documents={[{ uri: generateDocumentDownloadUrl(documentIdFromUrl), fileName: documentData?.documentName }]}
          prefetchMethod="GET"
          requestHeaders={{ userid: String(userProfile.id), secret: config.previewDocumentSecret }}
          pluginRenderers={DocViewerRenderers}
          config={{
            header: {
              overrideComponent: DocumentPreviewHeader,
            },
            loadingRenderer: {
              overrideComponent: DocumentPreviewLoader,
              showLoadingTimeout: false,
            },
            noRenderer: {
              overrideComponent: DocumentPreviewFallback,
            },
          }}
          className={stylesOverride?.documentPage}
        />
      )}
    </PageLayout>
  );
};

export default DocumentPreview;
